import React from "react";

import { ChatIcon, PeopleIcon, WorldIcon } from "../../../../assets/svgs";

import Button from "../../../Button";
import Title from "../../../Title";
import Label from "../../../Label";

import "./styles.scss";

export const ExploreSpaces = ({ spaces }) => {
  return (
    <article className="explore-section__item">
      <header className="explore-section__header">
        <Title size="small" font="medium">
          Explore spaces
        </Title>
      </header>
      {!!spaces &&
        spaces.length > 0 &&
        spaces.map((space) => (
          <SpaceItem
            key={space.id}
            spaceId={space.space_id}
            spaceIcon={space.avatar}
            spaceTitle={space.name}
            spaceMembers={space.total_members}
            spaceType={space.visibility}
            spacePostsAmount={space.number_of_posts_for_last_7_days}
          />
        ))}
    </article>
  );
};

const SpaceItem = ({
  spaceId,
  spaceTitle,
  spaceType,
  spaceMembers,
  spacePostsAmount,
  spaceIcon,
}) => {
  const goToSpace = () => {
    window.open(
      `${import.meta.env.VITE_REACT_APP_BRAINTRUST_APP_URL}spaces/${spaceId}/`,
      "_blank"
    );
  };

  return (
    <section className="space-item">
      <div className="space-item__details">
        <SpaceIcon src={spaceIcon} />
        <div className="space-item__details-info">
          <header>
            <Label size="medium" font="medium">
              {spaceTitle}
            </Label>
          </header>
          <section className="space-item__space-details">
            <div className="space-item__detail-item">
              <WorldIcon />
              <Label size="small">
                {spaceType === "public" ? "Public" : "Private"}
              </Label>
            </div>
            <div className="space-item__detail-item">
              <PeopleIcon />
              <Label size="small">{spaceMembers} members</Label>
            </div>
            <div className="space-item__detail-item">
              <ChatIcon />
              <Label size="small">{spacePostsAmount} new posts last week</Label>
            </div>
          </section>
          <Button
            onClick={goToSpace}
            styles="space-item__cta-button"
            border="round"
            type="primary"
            size="small"
          >
            Join space
          </Button>
        </div>
      </div>
    </section>
  );
};

const SpaceIcon = ({ src }) => {
  if (!!src) {
    return <img className="space-item__icon" src={src} alt="space-icon" />;
  }

  return <div className="space-item__icon space-item__icon--default-icon" />;
};
