import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import Button from "../Button";
import Label from "../Label";
import Title from "../Title";
import { Loader } from "../Loader"
import { CloseBlackIcon, BlackDetailIcon } from "../../assets/svgs";
import { numberFormatter } from "../../util";

export default function NetworkGrowth({ gsvData = [] }) {
  const [showIntro, setShowIntro] = useState(false);
  const [yaxisTickCnt, setYAxisTickCnt] = useState();
  const [yaxisMax, setYAxisMax] = useState();

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  const calculatingGraphData = () => {
    if (gsvData && gsvData.length > 0) {
      const lastData = gsvData[gsvData.length - 1];
      const maxData = gsvData;
      const maxValue = Math.max(
        lastData.value,
        parseInt(maxData[maxData.length - 1]?.value)
      );
      const yCount = Math.ceil(maxValue / 5000000);
      setYAxisTickCnt(yCount + 1);
      setYAxisMax(5000000 * yCount);
    }
  };

  useEffect(() => {
    calculatingGraphData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gsvData]);

  return (
    <div className="dashboard__growth" data-aos="fade-up" data-aos-delay="50">
      <div
        className={`dashboard__growth__main ${`dashboard__growth__main__${
          showIntro ? "hide" : "show"
        }`}`}
      >
        <div className="dashboard__growth__header">
          <Title type="primary" size="medium" font="medium">
            Braintrust Gross Service Value growth over time.
          </Title>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__growth__graph">
          {!gsvData.length && <Loader className="dashboard__growth__graph__loader" />}
          <ResponsiveContainer width="100%" height={283}>
            <LineChart
              width="100%"
              margin={{ top: 10, right: 40, bottom: 30, left: 0 }}
            >
              <Line
                type="monotone"
                data={gsvData}
                dataKey="value"
                stroke="#D4E4F9"
                strokeWidth={4}
                dot={false}
                activeDot={false}
              />
              <CartesianGrid stroke="#ccc" vertical={false} />
              <XAxis
                dataKey="date"
                dy={20}
                interval="preserveStartEnd"
                minTickGap={40}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => {
                  return new Date(value).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                  });
                }}
                allowDuplicatedCategory={false}
              />
              <YAxis
                type="number"
                domain={[0, yaxisMax]}
                tick={(t) => {
                  return (
                    <text
                      type="number"
                      orientation="left"
                      width={t.width}
                      height={t.height}
                      x={t.x}
                      y={t.y}
                      stroke="none"
                      fill="#28282B"
                      className="recharts-text recharts-cartesian-axis-tick-value"
                      textAnchor="end"
                    >
                      <tspan x="45" dy="0.355em">
                        {numberFormatter(t.payload.value)}
                      </tspan>
                    </text>
                  );
                }}
                tickCount={yaxisTickCnt}
                tickLine={false}
                axisLine={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div
        className={`dashboard__growth__intro ${`dashboard__growth__intro__${
          showIntro ? "show" : "hide"
        }`}`}
      >
        <div className="dashboard__growth__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__growth__intro__content">
          <div className="dashboard__growth__intro__content__item">
            <div className="dashboard__growth__intro__content__item__left">
              <Label type="primary" size="large" font="medium">
                Gross Service Value
              </Label>
            </div>
            <div className="dashboard__growth__intro__content__item__right">
              <Label type="primary" size="medium">
                GSV represents the actual dollar amount that has come into
                Braintrust before being distributed to the freelance talent.
                This is how Braintrust measures the network size and growth. For
                example, if a client hires three developers who are billing at
                10K/month. The GSV for the month would be $34.5k ($10k x 3 dev +
                15% BT fee).
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
