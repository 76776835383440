export const getGSVYears = (quartelyData = []) => {
  return quartelyData
    .filter((item) => item.quarter === null)
    .sort()
    .reverse()
    .reduce(
      (acc, item) => {
        acc.push({
          label: item.year,
          value: item.year,
        });
        return acc;
      },
      [
        {
          label: 'Total GSV',
          value: 'total',
        },
      ]
    );
};

export const getGSVQuaters = (quartelyData = [], selectedYear = 'total') => {
  if (selectedYear === 'total') {
    return [];
  }
  return quartelyData
    .filter((item) => item.quarter !== null && item.year === selectedYear)
    .sort()
    .reverse()
    .reduce(
      (acc, item) => {
        acc.push({
          label: `Q${item.quarter}`,
          value: item.quarter,
        });
        return acc;
      },
      [
        {
          label: 'Total',
          value: 'total',
        },
      ]
    );
};

export const getGSVValue = (
  data,
  quartelyData = [],
  selectedYear = 'total',
  selectedQuarter = 'total'
) => {
  if (selectedYear === 'total') {
    return data;
  }
  if (selectedQuarter === 'total') {
    return quartelyData.find(
      (item) => item.year === selectedYear && item.quarter === null
    ).value;
  }
  return quartelyData.find(
    (item) => item.year === selectedYear && item.quarter === selectedQuarter
  ).value;
};
