import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 0.5C2.964 0.5 0.5 2.964 0.5 6C0.5 9.036 2.964 11.5 6 11.5C9.036 11.5 11.5 9.036 11.5 6C11.5 2.964 9.036 0.5 6 0.5ZM1.6 6C1.6 5.6645 1.644 5.3345 1.7155 5.021L4.3445 7.65V8.2C4.3445 8.805 4.8395 9.3 5.4445 9.3V10.3615C3.283 10.0865 1.6 8.2385 1.6 6ZM9.2395 8.97C9.0965 8.5245 8.6895 8.2 8.1945 8.2H7.6445V6.55C7.6445 6.2475 7.397 6 7.0945 6H3.7945V4.9H4.8945C5.197 4.9 5.4445 4.6525 5.4445 4.35V3.25H6.5445C7.1495 3.25 7.6445 2.755 7.6445 2.15V1.9245C9.256 2.5735 10.4 4.1575 10.4 6C10.4 7.144 9.9545 8.189 9.2395 8.97Z"
        fill="#141417"
      />
    </svg>
  );
}

export default SvgComponent;
