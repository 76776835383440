import React from 'react';

export default function ButtonGroup({ value, options, onClick }) {
  return (
    <div className="button-group">
      {options.map((option) => (
        <button
          key={option.value}
          className={`button-group__button ${
            option.value === value ? 'button-group__button--selected' : ''
          }`}
          onClick={() => onClick(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}
