import React from "react";
import Label from "../Label";
import { ArrowDownIcon } from "../../assets/svgs";
import Menu from "../Menu";

export default function Dropdown({
  selected,
  styles,
  menu,
  open,
  prefix,
  position,
  iconColor,
  property,
  wrapperRef,
  onToggle,
  onItemClick,
}) {
  const selectedItem = menu.find((item) => item.value === selected);

  return (
    <div className={`drop-down ${styles ? styles : ""}`}>
      <div className="drop-down__toggle" onClick={onToggle}>
        <Label type="primary" size="small">
          {prefix ? <>{prefix}&nbsp;</> : ""}
        </Label>
        <Label styles="drop-down__toggle__label" type="primary" size="small">
          {selectedItem?.label}
        </Label>
        <div
          className={`drop-down__arrow-icon ${
            iconColor ? `drop-down__arrow-icon--${iconColor}` : ""
          }`}
        >
          <ArrowDownIcon width={13} height={13} />
        </div>
      </div>
      {open && (
        <Menu
          selected={selected}
          menu={menu}
          open={open}
          position={position}
          property={property}
          wrapperRef={wrapperRef}
          onToggle={onToggle}
          onItemClick={onItemClick}
        />
      )}
    </div>
  );
}
