import React from "react";

import { ButtonWithTooltip } from "../Button";
import Label from "../Label";
import Title from "../Title";
import { Loader } from "../Loader";
import {
  LogoIcon,
  FeeIcon,
  FeeArrowIconRight,
  FeeArrowIconDown,
  DetailsTooltipIcon,
} from "../../assets/svgs";
import { numberWithCommas } from "../../util";

export default function FeeConverter({ totalBTRST, totalUSD }) {
  const isLoading = !totalUSD && !totalBTRST

  const formattedTotalBTRST = numberWithCommas(
    Math.round((parseFloat(totalBTRST) + Number.EPSILON) * 100) / 100
  );
  const formattedTotalUSD = numberWithCommas(
    Math.round((parseFloat(totalUSD) + Number.EPSILON) * 100) / 100
  );

  return (
    <div
      className="dashboard__converter"
      data-aos="fade-up"
      data-aos-delay="50"
    >
      <div className="dashboard__converter__overlay" />
      <div className="dashboard__converter__main">
        <Title type="white" size="medium" font="medium">
          How the BTRST fee funds network growth
        </Title>
        {isLoading && <Loader variant="light" className="dashboard__converter__loader" />}
        {!isLoading && (
          <div className="dashboard__converter__content">
            <div className="dashboard__converter__client-fees">
              <div className="dashboard__converter__icon">
                <FeeIcon width={44} height={44} />
              </div>
              <div className="dashboard__converter__fee">
                ${formattedTotalUSD} USD
              </div>
              <Label type="white" size="large">
                in client fees
              </Label>
            </div>
            <div className="dashboard__converter__arrow-right">
              <FeeArrowIconRight width={120} height={59} />
            </div>
            <div className="dashboard__converter__arrow-down">
              <FeeArrowIconDown width={53} height={36} />
            </div>
            <div className="dashboard__converter__client-fees">
              <div className="dashboard__converter__icon">
                <LogoIcon width={44} height={44} />
              </div>
              <div className="dashboard__converter__fee">
                {formattedTotalBTRST} BTRST
              </div>
              <Label type="white" size="large">
                for the community
              </Label>
            </div>
          </div>
        )}
        <div>
          <div className="dashboard__converter__horizontal-line" />
          <Title
            type="white"
            size="small"
            font="small"
            styles="dashboard__converter__mb10"
          >
            How it works
          </Title>
        </div>
        <HowItWorksSection />
      </div>
    </div>
  );
}

const HowItWorksSection = () => {
  return (
    <div className="dashboard__converter__bottom-section">
      <div className="dashboard__converter__bottom-section__item">
        <CircleNumber children="1" />
        <div className="dashboard__converter__bottom-section__text">
          <Label type="white" size="medium">
            Client fees are used to buy BTRST tokens and sent to the{" "}
            <Link url="https://etherscan.io/address/0xb6f1f016175588a049fda12491cf3686de33990b#tokentxns">
              DAO.
            </Link>
            <ButtonWithTooltip
              styles="dashboard__converter__bottom-section__tooltip-button"
              size="small"
              tooltipText={
                <>
                  DAO tokens are reserved for future network development and can
                  only be moved by successfully passing an on-chain proposal by
                  BTRST token holders. See more on{" "}
                  <Link url="https://www.tally.xyz/gov/braintrust">
                    Tally.xyz
                  </Link>
                </>
              }
            >
              <DetailsTooltipIcon className="icon__tooltip-details" />
            </ButtonWithTooltip>
          </Label>
        </div>
      </div>
      <div className="dashboard__converter__bottom-section__item">
        <CircleNumber children="2" />
        <div className="dashboard__converter__bottom-section__text">
          <Label type="white" size="medium">
            Network development funds the{" "}
            <Link url="https://www.usebraintrust.com/professional-network">
              Professional Network
            </Link>
            , upgrading the recruiting experience through AI and other accretive
            experiences.
          </Label>
        </div>
      </div>
    </div>
  );
};

const CircleNumber = ({ children }) => {
  return (
    <div className="dashboard__converter__bottom-section__circle">
      {children}
    </div>
  );
};

const Link = ({ url, children }) => {
  return (
    <a
      href={url}
      className="dashboard__converter__link"
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};
