import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 13V5H11"
        stroke="#3B3B3B"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M19 5L5 19"
        stroke="#3B3B3B"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
