import { useEffect, useMemo, useState } from "react";
import { getSkillRates } from "../../actions/dashboard";

export const SORTS_SKILLS_OPTIONS = [
  { label: "A-Z", value: "skill" },
  { label: "Z-A", value: "-skill" },
  { label: "High to Low", value: "-rate" },
  { label: "Low to High", value: "rate" },
];

export const DEFAULT_MARKET_RATES_SKILLS_FILTERS_VALUES = {
  location: "world",
  order: "-rate",
  role: "all",
};

export const useMarketRatesForSkills = () => {
  const [skillsRateData, setSkillsRateData] = useState([]);
  const [filters, setFilters] = useState({
    ...DEFAULT_MARKET_RATES_SKILLS_FILTERS_VALUES,
  });
  const initialCurrentPage = useMemo(
    () => (skillsRateData.length === 0 ? 0 : 1),
    [skillsRateData.length]
  );
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);

  const DEFAULT_PAGE_SIZE = 10;
  const totalPage = Math.ceil(skillsRateData.length / DEFAULT_PAGE_SIZE) || 0;
  const currentData = skillsRateData.slice(
    (currentPage - 1) * DEFAULT_PAGE_SIZE,
    currentPage * DEFAULT_PAGE_SIZE
  );

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPage) setCurrentPage(currentPage + 1);
  };

  const updateFilter = (type, value) => {
    let newValue;
    switch (type) {
      case "role":
        newValue = { ...filters, role: value };
        break;
      case "location":
        newValue = {
          ...filters,
          location: value,
        };
        break;
      case "order":
        newValue = {
          ...filters,
          order: value,
        };
        break;
      default:
        return;
    }
    setFilters(newValue);
    getSkillsRateHandler(newValue);
  };

  const getSkillsRateHandler = async (params) => {
    const newParams = { ...params };
    if (newParams.role === DEFAULT_MARKET_RATES_SKILLS_FILTERS_VALUES.role) {
      delete newParams.role;
    }

    if (
      newParams.location === DEFAULT_MARKET_RATES_SKILLS_FILTERS_VALUES.location
    ) {
      delete newParams.location;
    }

    await getSkillRates(newParams).then((result) => {
      const { data, status_code } = result;
      if (status_code === 200) {
        setSkillsRateData(data.skill_rates);
      }
    });
  };

  useEffect(() => {
    getSkillsRateHandler(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //reset page when filters will change
    setCurrentPage(initialCurrentPage);
  }, [filters, initialCurrentPage]);

  return {
    currentData,
    currentPage,
    totalPage,
    filters,
    handleNextPage,
    handlePrevPage,
    updateFilter,
  };
};
