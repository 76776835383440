import React, { useState } from "react";

import Button from "../Button";
import Label from "../Label";
import Title from "../Title";

import { numberWithCommas } from "../../util";
import { BlackDetailIcon, CloseBlackIcon } from "../../assets/svgs";
import { Loader } from "../Loader";

export default function TalentJobMetrics({
  total_jobs,
  total_clients,
  total_talent,
  total_proposals,
}) {
  const [showIntro, setShowIntro] = useState(false);

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  const isLoading = !total_clients && !total_jobs && !total_talent && !total_proposals;

  return (
    <div
      className="dashboard__community-metrics"
      data-aos="fade-up"
      data-aos-delay="50"
    >
      <div
        className={`dashboard__community-metrics__main ${`dashboard__community-metrics__main__${
          showIntro ? "hide" : "show"
        }`}`}
      >
        <div className="dashboard__community-metrics__header">
          <Title type="primary" size="medium" font="medium">
            Community metrics
          </Title>
          <Button
            styles="dashboard__community-metrics__header__detail-button"
            onClick={onToggle}
          >
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        {isLoading && <Loader className="dashboard__community-metrics__loader" />}
        {!isLoading && (
          <div className="dashboard__community-metrics__content">
            <div className="dashboard__community-metrics__content__item">
              <div className="dashboard__community-metrics__content__item__display">
                <Label
                  type="primary"
                  size="large"
                  font="medium"
                  styles="dashboard__community-metrics__content__item__display__label"
                >
                  Total jobs
                </Label>

                <Title type="primary" font="medium" size="large">
                  {total_jobs ? numberWithCommas(total_jobs) : "N/A"}
                </Title>
              </div>
            </div>

            <div className="dashboard__community-metrics__content__item">
              <div className="dashboard__community-metrics__content__item__display">
                <Label
                  type="primary"
                  size="large"
                  font="medium"
                  styles="dashboard__community-metrics__content__item__display__label"
                >
                  Total clients
                </Label>

                <Title type="primary" font="medium" size="large">
                  {total_clients ? numberWithCommas(total_clients) : "N/A"}
                </Title>
              </div>
            </div>

            <div className="dashboard__community-metrics__content__item">
              <div className="dashboard__community-metrics__content__item__display">
                <Label
                  type="primary"
                  size="large"
                  font="medium"
                  styles="dashboard__community-metrics__content__item__display__label"
                >
                  Community members
                </Label>

                <Title type="primary" font="medium" size="large">
                  {total_talent ? numberWithCommas(total_talent) : "N/A"}
                </Title>
              </div>
            </div>

            <div className="dashboard__community-metrics__content__item">
              <div className="dashboard__community-metrics__content__item__display">
                <Label
                  type="primary"
                  size="large"
                  font="medium"
                  styles="dashboard__community-metrics__content__item__display__label"
                >
                  Talent applications
                </Label>

                <Title type="primary" font="medium" size="large">
                  {total_proposals ? numberWithCommas(total_proposals) : "N/A"}
                </Title>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={`dashboard__community-metrics__intro ${`dashboard__community-metrics__intro__${
          showIntro ? "show" : "hide"
        }`}`}
      >
        <div className="dashboard__community-metrics__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button
            styles="dashboard__community-metrics__header__detail-button"
            onClick={onToggle}
          >
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__community-metrics__intro__content">
          <div className="dashboard__community-metrics__intro__content__item">
            <Label type="primary" size="large" font="medium">
              Total Jobs
            </Label>
            <Label type="primary" size="medium">
              The total number of jobs that have been posted on the Braintrust
              platform.
            </Label>
          </div>
          <div className="dashboard__community-metrics__intro__content__item">
            <Label type="primary" size="large" font="medium">
              Community Members
            </Label>
            <Label type="primary" size="medium">
              The total number of community members on the Braintrust platform,
              including verified and not-yet verified talent and community
              members who aren't actively seeking a new opportunity.
            </Label>
          </div>
          <div className="dashboard__community-metrics__intro__content__item">
            <Label type="primary" size="large" font="medium">
              Talent Applications
            </Label>
            <Label type="primary" size="medium">
              The total number of aplications that have been submitted by
              Braintrust talent.
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
}
