import React, { useState } from "react";

const ButtonWithTooltip = ({
  border,
  disabled,
  size,
  styles,
  type,
  onClick,
  children,
  tooltipText,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const defaultOnClickHandler = () => {
    setIsTooltipVisible((isVisible) => !isVisible);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`${styles} button button__${type} button__${size} button__${border} ${
        disabled ? "button__disabled" : ""
      }`}
      onClick={onClick || defaultOnClickHandler}
      disabled={disabled}
    >
      {children}
      {isTooltipVisible && (
        <div className={`tooltip-content ${isTooltipVisible ? "visible" : ""}`}>
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default ButtonWithTooltip;
