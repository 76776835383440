import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.14729 10.843L5.34128 10.1186C5.16418 10.0711 4.97585 10.0902 4.81188 10.1722L5.14729 10.843ZM2.33335 12.25L1.59089 12.1439C1.55144 12.4201 1.66852 12.6953 1.89482 12.8584C2.12112 13.0215 2.41926 13.0456 2.66876 12.9208L2.33335 12.25ZM2.7144 9.58265L3.45687 9.68871C3.49294 9.43619 3.39819 9.18265 3.20536 9.01567L2.7144 9.58265ZM7.00002 11.8333C10.4711 11.8333 13.5834 9.55573 13.5834 6.41667H12.0834C12.0834 8.43226 9.97229 10.3333 7.00002 10.3333V11.8333ZM4.9533 11.5675C5.59903 11.7404 6.28727 11.8333 7.00002 11.8333V10.3333C6.41748 10.3333 5.85971 10.2574 5.34128 10.1186L4.9533 11.5675ZM2.66876 12.9208L5.4827 11.5139L4.81188 10.1722L1.99794 11.5792L2.66876 12.9208ZM1.97194 9.47658L1.59089 12.1439L3.07582 12.3561L3.45687 9.68871L1.97194 9.47658ZM0.416687 6.41667C0.416687 7.89243 1.12776 9.20086 2.22345 10.1496L3.20536 9.01567C2.37963 8.30066 1.91669 7.38468 1.91669 6.41667H0.416687ZM7.00002 1C3.52897 1 0.416687 3.2776 0.416687 6.41667H1.91669C1.91669 4.40108 4.02775 2.5 7.00002 2.5V1ZM13.5834 6.41667C13.5834 3.2776 10.4711 1 7.00002 1V2.5C9.97229 2.5 12.0834 4.40108 12.0834 6.41667H13.5834Z"
        fill="#141417"
      />
    </svg>
  );
}

export default SvgComponent;
