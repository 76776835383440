export const Avatar = ({
  className,
  avatarUrl,
  firstName,
  lastInitial,
  initials,
}) => {
  if (avatarUrl) {
    return (
      <img
        className={className}
        src={avatarUrl}
        alt="user avatar"
        width={30}
        height={30}
      />
    );
  }

  if (!!initials) {
    return <span className={`${className} avatar`}>{initials}</span>;
  }

  return (
    <span className={`${className} avatar`}>
      {`${firstName ? firstName[0] : ""}${lastInitial}
      `.toUpperCase()}
    </span>
  );
};
