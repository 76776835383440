import React, { useEffect, useRef, useState } from "react";

import { ArrowRightIcon } from "../../../../assets/svgs";

import Button from "../../../Button";
import Title from "../../../Title";
import Label from "../../../Label";

import { Avatar } from "../../Avatar";

import "./styles.scss";

export const ExplorePosts = ({ posts }) => {
  const animationTimeOut = useRef();
  const [animate, setAnimate] = useState(false);
  const [currentPostId, setCurrentPostId] = useState(posts[0].data.id);
  const postsIds = posts.map((post) => post.data.id);
  const currentPostIndex = postsIds.findIndex((id) => id === currentPostId);
  const maxIndex = posts.length - 1;
  const { data: currentPost } = posts.find(
    (post) => post.data.id === currentPostId
  );

  const goToPostOnFeedPage = () => {
    window.open(
      `${import.meta.env.VITE_REACT_APP_BRAINTRUST_APP_URL}feed/${currentPost.id}/`,
      "_blank"
    );
  };

  const handleChangeContent = () => {
    setAnimate(true);
    animationTimeOut.current = setTimeout(() => setAnimate(false), 500); // Reset after animation completes
  };

  const handleClickNextPostButton = (e) => {
    if (currentPostIndex === maxIndex) {
      setCurrentPostId(postsIds[0]);
      handleChangeContent();
      e.stopPropagation();
      return;
    }
    setCurrentPostId(postsIds[currentPostIndex + 1]);
    handleChangeContent();
    e.stopPropagation();
  };

  const handleClickPreviousPostButton = (e) => {
    if (currentPostIndex === 0) {
      setCurrentPostId(postsIds[maxIndex]);
      handleChangeContent();
      e.stopPropagation();
      return;
    }
    setCurrentPostId(postsIds[currentPostIndex - 1]);
    handleChangeContent();
    e.stopPropagation();
  };

  useEffect(() => {
    return () => {
      clearTimeout(animationTimeOut.current);
    };
  }, []);

  return (
    <article className="explore-section__item">
      <header className="explore-section__header">
        <Title size="small" font="medium">
          Explore top posts
        </Title>
      </header>
      <section
        className="explore-posts post-prompt"
        onClick={goToPostOnFeedPage}
      >
        <Button
          styles="explore-post-nav-button prev"
          onClick={handleClickPreviousPostButton}
          size="small"
        >
          <ArrowRightIcon width={18} height={18} />
        </Button>
        <div className={`${animate ? "animate" : ""}`}>
          <header className="explore-posts__header">
            <Avatar
              className="explore-posts__avatar"
              avatarUrl={currentPost.freelancer.user.avatar}
              initials={currentPost.freelancer.user.initials}
            />
            <div className="explore-posts__author">
              <Label
                size="medium"
                font="medium"
                className="explore-posts__author__name"
              >
                {currentPost.freelancer.user.public_name}
              </Label>
              {!!currentPost.freelancer.role && (
                <Label
                  size="small"
                  type="secondary"
                  className="explore-posts__author__role"
                >
                  {currentPost.freelancer.role.name}
                </Label>
              )}
            </div>
          </header>
          <PostCategoryBadge category={currentPost.category} />
          <section className="explore-posts__content">
            <Label size="large">{currentPost.title}</Label>
            <Label size="medium">{currentPost.text}</Label>
          </section>
        </div>
        <div className="explore-posts__card-effect-before" />
        <div className="explore-posts__card-effect-after" />
        <Button
          styles="explore-post-nav-button next"
          onClick={handleClickNextPostButton}
          size="small"
        >
          <ArrowRightIcon width={18} height={18} />
        </Button>
      </section>
    </article>
  );
};

const PostCategoryBadge = ({ category }) => {
  if (!category) {
    return null;
  }

  return (
    <div className={`explore-posts__category-tag badge-color${category.color}`}>
      {category.emoji} {category.name}
    </div>
  );
};
