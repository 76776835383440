import React, { useCallback, useEffect, useRef, useState } from "react";

import { LeftArrowIcon, RightArrowIcon } from "../../assets/svgs";
import Label from "../Label";
import Button from "../Button";

import "./styles.scss";

export const TabCarousel = ({ list, selected, onTabItemClick, property }) => {
  const [translateValue, setTranslateValue] = useState(0);
  const lastItemRef = useRef(null);
  const containerRef = useRef(null);
  const [isLastItemVisible, setIsLastItemVisible] = useState(false);

  const checkVisibility = useCallback(() => {
    if (lastItemRef.current && containerRef.current) {
      const itemRect = lastItemRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      return (
        itemRect.top >= containerRect.top &&
        itemRect.left > containerRect.left &&
        itemRect.bottom <= containerRect.bottom &&
        itemRect.right < containerRect.right
      );
    }
    return false;
  }, []);

  const moveLeft = useCallback(() => {
    const isLastItemVisible = checkVisibility();
    if (!isLastItemVisible) {
      setTranslateValue((prevValue) => Math.max(prevValue - 10, -150));
    }
    setIsLastItemVisible(isLastItemVisible);
  }, [checkVisibility]);

  const moveRight = useCallback(() => {
    const isLastItemVisible = checkVisibility();
    setIsLastItemVisible(isLastItemVisible);
    setTranslateValue((prevValue) => Math.min(prevValue + 10, 100));
  }, [checkVisibility]);

  useEffect(() => {
    const handleResize = () => {
      const isLastItemVisible = checkVisibility();
      setIsLastItemVisible(isLastItemVisible);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [checkVisibility]);

  const shouldShowLeftNavButton = translateValue !== 0;
  const shouldShowRightNavButton = !isLastItemVisible;

  return (
    <div
      className={`carousel ${
        shouldShowLeftNavButton ? "carousel--with-prev-button-backdrop" : ""
      } ${
        shouldShowRightNavButton ? "carousel--with-next-button-backdrop" : ""
      }`}
      ref={containerRef}
    >
      {shouldShowLeftNavButton && (
        <Button styles="carousel-button prev" onClick={moveRight} size="small">
          <LeftArrowIcon />
        </Button>
      )}

      <div
        className="carousel-container"
        style={{
          transform: `translateX(${translateValue}%)`,
          transition: "transform 0.3s ease-in-out",
        }}
      >
        {list.map((item, index) => {
          const isActive = item.value === selected;
          return (
            <div
              key={item.value}
              ref={index === list.length - 1 ? lastItemRef : null}
              className={`carousel-item ${
                isActive ? "carousel-item__active" : ""
              }`}
              onClick={() => onTabItemClick(property, item.value)}
            >
              <Label
                type={isActive ? "primary" : "secondary"}
                size="small"
                font={selected ? "medium" : "regular"}
              >
                {item.label}
              </Label>
            </div>
          );
        })}
      </div>

      {shouldShowRightNavButton && (
        <Button size="small" styles="carousel-button next" onClick={moveLeft}>
          <RightArrowIcon />
        </Button>
      )}
    </div>
  );
};
