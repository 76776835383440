import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.666687 12C0.666687 10.5 2.66669 10.5 3.66669 9.5C4.16669 9 2.66669 9 2.66669 6.5C2.66669 4.8335 3.33319 4 4.66669 4C6.00019 4 6.66669 4.8335 6.66669 6.5C6.66669 9 5.16669 9 5.66669 9.5C6.66669 10.5 8.66669 10.5 8.66669 12"
        stroke="#141417"
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M7.99646 10.8287C8.37446 10.5682 8.86516 10.3807 9.33471 10.1545C9.70182 9.9777 10.056 9.7773 10.3333 9.5C10.8333 9 9.3333 9 9.3333 6.5C9.3333 4.8335 9.9998 4 11.3333 4C12.6668 4 13.3333 4.8335 13.3333 6.5C13.3333 9 11.8333 9 12.3333 9.5C13.3333 10.5 15.3333 10.5 15.3333 12"
        stroke="#141417"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
