import React from "react";

import { SIGN_UP_LINK } from "../../../constants";
import BTRSTCurrency from "../../../assets/imgs/btrst-currency.png";
import CoinbaseIcon from "../../../assets/imgs/coinbase-icon.png";
import UniswapIcon from "../../../assets/imgs/uniswap-icon.png";
import { ArrowRightUpIcon } from "../../../assets/svgs";

import Button from "../../Button";
import Label from "../../Label";
import Title from "../../Title";

import "./styles.scss";

export const BTRSTMarketSection = () => {
  return (
    <section className="btrst-market-section">
      <div className="btrst-market-section__content">
        <Title styles="btrst-market-section__title">
          The first decentralized talent network.
        </Title>
        <Label styles="btrst-market-section__text" size="medium">
          Ownership and governance in the Braintrust Network is represented by
          the BTRST token. The token is used as an incentive to reward our
          community for building the decentralized network, inviting and vetting
          talent, and referring clients.
        </Label>
        <Button
          styles="btrst-market-section__cta-button"
          border="round"
          type="primary"
          onClick={() => {
            return window.open(SIGN_UP_LINK, "_blank");
          }}
        >
          Join Braintrust
        </Button>
      </div>
      <BuyPrompt />
    </section>
  );
};

const BuyPrompt = () => {
  return (
    <div className="buy-prompt">
      <div className="buy-prompt__inside-rect">
        <img
          className="buy-prompt__img"
          src={BTRSTCurrency}
          alt="btrst-currency"
        />
        <div className="buy-prompt__wrapper">
          <Label styles="buy-prompt__title">Buy BTRST on:</Label>

          <Button
            onClick={() => {
              return window.open(
                "https://www.coinbase.com/price/braintrust",
                "_blank"
              );
            }}
            styles="buy-prompt__market-item"
          >
            <div className="buy-prompt__market-name">
              <img src={CoinbaseIcon} alt="coinbase-icon" />
              <Label styles="buy-prompt__market-title">Coinbase</Label>
            </div>
            <ArrowRightUpIcon />
          </Button>

          <Button
            onClick={() => {
              return window.open(
                "https://app.uniswap.org/explore/tokens/base/0xa7d68d155d17cb30e311367c2ef1e82ab6022b67",
                "_blank"
              );
            }}
            styles="buy-prompt__market-item"
          >
            <div className="buy-prompt__market-name">
              <img src={UniswapIcon} alt="uniswap-icon" />
              <Label styles="buy-prompt__market-title">Uniswap</Label>
            </div>
            <ArrowRightUpIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};
