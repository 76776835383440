import React from "react";
import Label from "../Label";
import { VerticalLine } from "../../assets/svgs";

export default function Menu({
  selected,
  menu,
  open,
  property,
  position,
  wrapperRef,
  onToggle,
  onItemClick,
}) {
  return (
    <div
      ref={wrapperRef}
      className={`drop-down__list ${
        position ? `drop-down__list__${position}` : ""
      } ${`drop-down__list__${open ? "show" : "hide"}`}`}
    >
      {menu.map((item) => {
        return (
          <div
            className="drop-down__item"
            key={item.value}
            onClick={() => {
              onItemClick(property, item.value);
              onToggle();
            }}
          >
            {item.value === selected && (
              <div className="drop-down__item__left">
                <VerticalLine width={4.8} height={32.01} />
              </div>
            )}
            <Label size="medium" type="primary">
              {item.label}
            </Label>
          </div>
        );
      })}
    </div>
  );
}
