import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 9L2.25 9"
        stroke="#141417"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6.75 4.5L2.25 9L6.75 13.5"
        stroke="#141417"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
