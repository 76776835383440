import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.25 9H15.75"
        stroke="#141417"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M11.25 13.5L15.75 9L11.25 4.5"
        stroke="#141417"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
