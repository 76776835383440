import React, { useState, useEffect, useRef } from "react";

import { TabCarousel } from "../TabCarousel";
import Button from "../Button";
import Dropdown from "../Dropdown";
import Label from "../Label";
import Title from "../Title";
import {
  ChevronLeftIcon,
  ChevronLeftActiveIcon,
  ChevronRightIcon,
  ChevronRightActiveIcon,
  CloseBlackIcon,
  BlackDetailIcon,
} from "../../assets/svgs";
import { regions } from "../../constants";
import {
  SORTS_SKILLS_OPTIONS,
  useMarketRatesForSkills,
} from "../../hooks/market-rates-skills";
import { Loader } from "../Loader";

const tabList = [
  { label: "All", value: "all" },
  { label: "Engineering", value: "Engineering" },
  { label: "Design", value: "Design" },
  { label: "Product", value: "Product" },
  { label: "Marketing", value: "Marketing" },
  {
    label: "Project/Program Management",
    value: "Project & Program Management",
  },
];

export default function AverageMarketRates() {
  const {
    currentData,
    currentPage,
    totalPage,
    filters,
    handleNextPage,
    handlePrevPage,
    updateFilter,
  } = useMarketRatesForSkills();
  const [openRegion, setOpenRegion] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [showIntro, setShowIntro] = useState(false);

  const regionRef = useRef();
  const sortRef = useRef();

  const handleClickOutside = (event) => {
    if (openRegion && regionRef && !regionRef.current.contains(event.target))
      setOpenRegion(false);
    if (openSort && sortRef && !sortRef.current.contains(event.target))
      setOpenSort(false);
  };

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  const Pagination = () => (
    <div className="dashboard__leaderboard__pagination">
      <Button
        styles="dashboard__leaderboard__pagination__btn-prev"
        disabled={currentPage === 1}
        onClick={handlePrevPage}
      >
        {currentPage === 1 ? <ChevronLeftIcon /> : <ChevronLeftActiveIcon />}
      </Button>
      <Label type="primary" size="small">
        Page {currentPage} of {totalPage}
      </Label>
      <Button
        styles="dashboard__leaderboard__pagination__btn-next"
        disabled={currentPage === totalPage}
        onClick={handleNextPage}
      >
        {currentPage === totalPage ? (
          <ChevronRightIcon />
        ) : (
          <ChevronRightActiveIcon />
        )}
      </Button>
    </div>
  );

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRegion, openSort]);

  return (
    <div
      className="dashboard__leaderboard__rates"
      data-aos="fade-up"
      data-aos-delay="1"
    >
      <div
        className={`dashboard__leaderboard__rates__main ${`dashboard__leaderboard__rates__main__${
          showIntro ? "hide" : "show"
        }`}`}
      >
        <div className="dashboard__leaderboard__rates__header">
          <Title
            styles="dashboard__leaderboard__rates__header__title"
            type="primary"
            size="small"
            font="medium"
          >
            Average market rates
          </Title>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__leaderboard__rates__content">
          <TabCarousel
            list={tabList}
            selected={filters.role}
            property="role"
            onTabItemClick={updateFilter}
          />
          <div className="dashboard__leaderboard__rates__content__filter">
            <div className="dashboard__leaderboard__rates__content__filter__left">
              <Dropdown
                open={openRegion}
                selected={filters.location}
                menu={regions}
                wrapperRef={regionRef}
                onToggle={() => setOpenRegion(!openRegion)}
                property="location"
                onItemClick={updateFilter}
                iconColor="blue"
              />
            </div>
            <div className="dashboard__leaderboard__rates__content__filter__right">
              <Dropdown
                open={openSort}
                prefix="Sort"
                position="right"
                selected={filters.order}
                menu={SORTS_SKILLS_OPTIONS}
                wrapperRef={sortRef}
                onToggle={() => setOpenSort(!openSort)}
                property="order"
                onItemClick={updateFilter}
                iconColor="blue"
              />
            </div>
          </div>
          <div className="dashboard__leaderboard__rates__list">
            {!currentData.length && <Loader />}
            {currentData.map((item) => {
              return (
                <div
                  className="dashboard__leaderboard__rates__item"
                  key={`${item.skill}-${item.avg_rate}-${item.root_role}`}
                >
                  <div className="dashboard__leaderboard__rates__item__left">
                    <Label
                      type="primary"
                      size="large"
                      styles="dashboard__leaderboard__rates__item__name text-ellipsis"
                      title={item.skill}
                    >
                      {item.skill}
                    </Label>
                  </div>
                  <div className="dashboard__leaderboard__rates__item__right">
                    <Label type="primary" size="large" font="medium">
                      ${Math.ceil(item.avg_rate)}/hr USD
                    </Label>
                  </div>
                </div>
              );
            })}
          </div>
          <Pagination />
        </div>
      </div>
      <div
        className={`dashboard__leaderboard__rates__intro ${`dashboard__leaderboard__rates__intro__${
          showIntro ? "show" : "hide"
        }`}`}
      >
        <div className="dashboard__leaderboard__rates__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__leaderboard__rates__intro__content">
          <div className="dashboard__leaderboard__rates__intro__content__item">
            <Label type="primary" size="large" font="medium">
              Average Skill Market Rates
            </Label>
            <br />
            <Label type="primary" size="medium">
              Skill Rates are calculated from projects that have hired
              Braintrust talent. Using the tagged skills on the job list, the
              hired date, and the location of the talent, this metric calculates
              averages and creates a market rate. As additional jobs are added
              to the platform, this rate will continue to get more granular.
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
}
