import React from "react";

import { numberWithCommas } from "../../../util";

import Label from "../../Label";
import Title from "../../Title";

import { ExploreSpaces } from "./explore-spaces";
import { ExplorePosts } from "./explore-posts";
import { Loader } from "../../Loader";

import "./styles.scss";

export const ProfessionalNetwork = ({
  totalActiveUsers,
  monthlyActiveUsers,
  totalConversations,
  posts,
  spaces,
}) => {
  const isLoading = !spaces || !posts;
  return (
    <section className="professional-network">
      <header className="professional-network__header">
        <Title size="medium" font="medium">
          Professional Network
        </Title>
        <Label styles="professional-network__header-caption" size="large">
          Join the conversation and advance your career
        </Label>
      </header>

      {isLoading 
        ? <Loader /> 
        : <>
            <NetworkMetrics
              totalActiveUsers={totalActiveUsers}
              monthlyActiveUsers={monthlyActiveUsers}
              totalConversations={totalConversations}
            />
            <section className="explore-section">
              {!!posts && posts.length > 0 && <ExplorePosts posts={posts} />}
              <ExploreSpaces spaces={spaces} />
            </section>
          </> 
        }
    </section>
  );
};

const NetworkMetrics = ({
  totalActiveUsers,
  monthlyActiveUsers,
  totalConversations,
}) => {
  return (
    <section className="network-metrics">
      <section className="network-metrics__data-item">
        <Label type="primary" size="large" font="medium">
          Total career conversations
        </Label>

        <Title type="primary" font="medium" size="large">
          {numberWithCommas(totalConversations || "N/A")}
        </Title>
      </section>

      <section className="network-metrics__data-item">
        <Label type="primary" size="large" font="medium">
          Total active users
        </Label>

        <Title type="primary" font="medium" size="large">
          {numberWithCommas(totalActiveUsers || "N/A")}
        </Title>
      </section>

      <section className="network-metrics__data-item">
        <Label type="primary" size="large" font="medium">
          Monthly active users
        </Label>

        <Title type="primary" font="medium" size="large">
          {numberWithCommas(monthlyActiveUsers || "N/A")}
        </Title>
      </section>
    </section>
  );
};
